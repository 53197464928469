import {TcodeStatusDTO} from '../dto/tcode-status-dto';
import {UserDTO} from '../dto/user-dto';
import {TcodeCityDTO} from '../dto/tcode-city-dto';
import {TcodeOccupationDTO} from '../dto/tcode-occupation-dto';
import {CustomerWishlistDTO} from '../dto/customer-wishlist-dto';
import {CustomerFriendsDTO} from '../dto/customer-friends-dto';
import {CustomerCuisinesDTO} from '../dto/customer-cuisines-dto';
import {RestReturnCustomerDTO} from '../dto/rest-return-customer-dto';
import {CustomerOfferDTO} from '../dto/customer-offer-dto';

export class BaseCustomerDTO extends UserDTO {

	name:string;
	dob:string;
	gender:string;
	cityDTO:TcodeCityDTO = new TcodeCityDTO();
	location:string;
	maritalstatus:string;
	occupationDTO:TcodeOccupationDTO = new TcodeOccupationDTO();
	photo:string;
	statusDTO:TcodeStatusDTO = new TcodeStatusDTO();
	openness1:number;
	openness2:number;
	consciousness1:number;
	consciousness2:number;
	extraversion1:number;
	extraversion2:number;
	agreeableness1:number;
	agreeableness2:number;
	neuroticism1:number;
	neuroticism2:number;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	customerWishlistList:Array<CustomerWishlistDTO> = new Array<CustomerWishlistDTO>();
	customerFriendsList:Array<CustomerFriendsDTO> = new Array<CustomerFriendsDTO>();
	customerCuisinesList:Array<CustomerCuisinesDTO> = new Array<CustomerCuisinesDTO>();
	restReturnCustomerList:Array<RestReturnCustomerDTO> = new Array<RestReturnCustomerDTO>();
	customerOfferList:Array<CustomerOfferDTO> = new Array<CustomerOfferDTO>();
}