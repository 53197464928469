import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {LoginHistDTO} from '../dto/login-hist-dto';
import {UserDTO} from '../dto/user-dto';
import {DeviceDTO} from '../dto/device-dto';

@Injectable()
export class BaseLoginHistService {

	constructor(public api:Api) {

	}

	getLoginHistById(id:number) : Observable<LoginHistDTO> {

		return this.api.post<LoginHistDTO>("LoginHist/getLoginHistById", id);
	}

	getLoginHist(loginHistDTO:LoginHistDTO) : Observable<LoginHistDTO> {

		return this.api.post<LoginHistDTO>("LoginHist/getLoginHist", loginHistDTO);
	}

	getLoginHistsByUserId(userDTO:UserDTO) : Observable<UserDTO> {

		return this.api.post<UserDTO>("LoginHist/getLoginHistsByUserId", userDTO);
	}

	getLoginHistListByUserId(userId:number) : Observable<Array<LoginHistDTO>> {

		return this.api.post<Array<LoginHistDTO>>("LoginHist/getLoginHistListByUserId", userId);
	}

	getLoginHistMapByUserId(userId:number) : Observable<Map<number, LoginHistDTO>>  {

		return this.api.post<Map<number, LoginHistDTO>>("LoginHist/getLoginHistMapByUserId", userId);
	}

	getLoginHistsByDeviceId(deviceDTO:DeviceDTO) : Observable<DeviceDTO> {

		return this.api.post<DeviceDTO>("LoginHist/getLoginHistsByDeviceId", deviceDTO);
	}

	getLoginHistListByDeviceId(deviceId:number) : Observable<Array<LoginHistDTO>> {

		return this.api.post<Array<LoginHistDTO>>("LoginHist/getLoginHistListByDeviceId", deviceId);
	}

	getLoginHistMapByDeviceId(deviceId:number) : Observable<Map<number, LoginHistDTO>>  {

		return this.api.post<Map<number, LoginHistDTO>>("LoginHist/getLoginHistMapByDeviceId", deviceId);
	}

	getLoginHistList() : Observable<LoginHistDTO[]> {

		return this.api.get<LoginHistDTO[]>("LoginHist/getLoginHistList");
	}

	getLoginHistMap() : Observable<Map<number, LoginHistDTO>> {

		return this.api.get<Map<number, LoginHistDTO>>("LoginHist/getLoginHistMap");
	}

	searchLoginHistList(loginHistDTO:LoginHistDTO) : Observable<Array<LoginHistDTO>> {

		return this.api.post<Array<LoginHistDTO>>("LoginHist/searchLoginHistList", loginHistDTO);
	}

	searchLoginHistMap(loginHistDTO:LoginHistDTO) : Observable<Map<number, LoginHistDTO>> {

		return this.api.post<Map<number, LoginHistDTO>>("LoginHist/searchLoginHistMap", loginHistDTO);
	}

	addLoginHist(loginHistDTO:LoginHistDTO) : Observable<LoginHistDTO> {

		return this.api.post<LoginHistDTO>("LoginHist/addLoginHist", loginHistDTO);
	}

	addLoginHistList(loginHistList:Array<LoginHistDTO>) : Observable<Array<LoginHistDTO>> {

		return this.api.post<Array<LoginHistDTO>>("LoginHist/addLoginHistList", loginHistList);
	}

	updateLoginHist(loginHistDTO:LoginHistDTO) : Observable<LoginHistDTO> {

		return this.api.post<LoginHistDTO>("LoginHist/updateLoginHist", loginHistDTO);
	}

	updateLoginHistList(loginHistList:Array<LoginHistDTO>) : Observable<Array<LoginHistDTO>> {

		return this.api.post<Array<LoginHistDTO>>("LoginHist/updateLoginHistList", loginHistList);
	}

	saveLoginHist(loginHistDTO:LoginHistDTO) : Observable<LoginHistDTO> {

		return this.api.post<LoginHistDTO>("LoginHist/saveLoginHist", loginHistDTO);
	}

	saveLoginHistList(loginHistList:Array<LoginHistDTO>) : Observable<Array<LoginHistDTO>> {

		return this.api.post<Array<LoginHistDTO>>("LoginHist/saveLoginHistList", loginHistList);
	}

	activateLoginHistById(id:number) : Observable<number>{

		return this.api.post<number>("LoginHist/activateLoginHistById", id);
	}

	activateLoginHist(loginHistDTO:LoginHistDTO) : Observable<LoginHistDTO> {

		return this.api.post<LoginHistDTO>("LoginHist/activateLoginHist", loginHistDTO);
	}

	activateLoginHistList(loginHistList:Array<LoginHistDTO>) : Observable<Array<LoginHistDTO>> {

		return this.api.post<Array<LoginHistDTO>>("LoginHist/activateLoginHistList", loginHistList);
	}

	deactivateLoginHistById(id:number) : Observable<number> {

		return this.api.post<number>("LoginHist/deactivateLoginHistById", id);
	}

	deactivateLoginHist(loginHistDTO:LoginHistDTO) : Observable<LoginHistDTO> {

		return this.api.post<LoginHistDTO>("LoginHist/deactivateLoginHist", loginHistDTO);
	}

	deactivateLoginHistList(loginHistList:Array<LoginHistDTO>) : Observable<Array<LoginHistDTO>> {

		return this.api.post<Array<LoginHistDTO>>("LoginHist/deactivateLoginHistList", loginHistList);
	}
}