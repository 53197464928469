import {UserDTO} from '../dto/user-dto';

export class BaseRoleDTO {

	id:number;
	englishName:string;
	arabicName:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	userList:Array<UserDTO> = new Array<UserDTO>();
}