import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {RestaurantDesignDTO} from '../dto/restaurant-design-dto';
import {TcodeRestDesignDTO} from '../dto/tcode-rest-design-dto';
import {RestaurantDTO} from '../dto/restaurant-dto';

@Injectable()
export class BaseRestaurantDesignService {

	constructor(public api:Api) {

	}

	getRestaurantDesignById(id:number) : Observable<RestaurantDesignDTO> {

		return this.api.post<RestaurantDesignDTO>("RestaurantDesign/getRestaurantDesignById", id);
	}

	getRestaurantDesign(restaurantDesignDTO:RestaurantDesignDTO) : Observable<RestaurantDesignDTO> {

		return this.api.post<RestaurantDesignDTO>("RestaurantDesign/getRestaurantDesign", restaurantDesignDTO);
	}

	getRestaurantDesignsByDesignId(tcodeRestDesignDTO:TcodeRestDesignDTO) : Observable<TcodeRestDesignDTO> {

		return this.api.post<TcodeRestDesignDTO>("RestaurantDesign/getRestaurantDesignsByDesignId", tcodeRestDesignDTO);
	}

	getRestaurantDesignListByDesignId(designId:number) : Observable<Array<RestaurantDesignDTO>> {

		return this.api.post<Array<RestaurantDesignDTO>>("RestaurantDesign/getRestaurantDesignListByDesignId", designId);
	}

	getRestaurantDesignMapByDesignId(designId:number) : Observable<Map<number, RestaurantDesignDTO>>  {

		return this.api.post<Map<number, RestaurantDesignDTO>>("RestaurantDesign/getRestaurantDesignMapByDesignId", designId);
	}

	getRestaurantDesignsByRestaurantId(restaurantDTO:RestaurantDTO) : Observable<RestaurantDTO> {

		return this.api.post<RestaurantDTO>("RestaurantDesign/getRestaurantDesignsByRestaurantId", restaurantDTO);
	}

	getRestaurantDesignListByRestaurantId(restaurantId:number) : Observable<Array<RestaurantDesignDTO>> {

		return this.api.post<Array<RestaurantDesignDTO>>("RestaurantDesign/getRestaurantDesignListByRestaurantId", restaurantId);
	}

	getRestaurantDesignMapByRestaurantId(restaurantId:number) : Observable<Map<number, RestaurantDesignDTO>>  {

		return this.api.post<Map<number, RestaurantDesignDTO>>("RestaurantDesign/getRestaurantDesignMapByRestaurantId", restaurantId);
	}

	getRestaurantDesignList() : Observable<RestaurantDesignDTO[]> {

		return this.api.get<RestaurantDesignDTO[]>("RestaurantDesign/getRestaurantDesignList");
	}

	getRestaurantDesignMap() : Observable<Map<number, RestaurantDesignDTO>> {

		return this.api.get<Map<number, RestaurantDesignDTO>>("RestaurantDesign/getRestaurantDesignMap");
	}

	searchRestaurantDesignList(restaurantDesignDTO:RestaurantDesignDTO) : Observable<Array<RestaurantDesignDTO>> {

		return this.api.post<Array<RestaurantDesignDTO>>("RestaurantDesign/searchRestaurantDesignList", restaurantDesignDTO);
	}

	searchRestaurantDesignMap(restaurantDesignDTO:RestaurantDesignDTO) : Observable<Map<number, RestaurantDesignDTO>> {

		return this.api.post<Map<number, RestaurantDesignDTO>>("RestaurantDesign/searchRestaurantDesignMap", restaurantDesignDTO);
	}

	addRestaurantDesign(restaurantDesignDTO:RestaurantDesignDTO) : Observable<RestaurantDesignDTO> {

		return this.api.post<RestaurantDesignDTO>("RestaurantDesign/addRestaurantDesign", restaurantDesignDTO);
	}

	addRestaurantDesignList(restaurantDesignList:Array<RestaurantDesignDTO>) : Observable<Array<RestaurantDesignDTO>> {

		return this.api.post<Array<RestaurantDesignDTO>>("RestaurantDesign/addRestaurantDesignList", restaurantDesignList);
	}

	updateRestaurantDesign(restaurantDesignDTO:RestaurantDesignDTO) : Observable<RestaurantDesignDTO> {

		return this.api.post<RestaurantDesignDTO>("RestaurantDesign/updateRestaurantDesign", restaurantDesignDTO);
	}

	updateRestaurantDesignList(restaurantDesignList:Array<RestaurantDesignDTO>) : Observable<Array<RestaurantDesignDTO>> {

		return this.api.post<Array<RestaurantDesignDTO>>("RestaurantDesign/updateRestaurantDesignList", restaurantDesignList);
	}

	saveRestaurantDesign(restaurantDesignDTO:RestaurantDesignDTO) : Observable<RestaurantDesignDTO> {

		return this.api.post<RestaurantDesignDTO>("RestaurantDesign/saveRestaurantDesign", restaurantDesignDTO);
	}

	saveRestaurantDesignList(restaurantDesignList:Array<RestaurantDesignDTO>) : Observable<Array<RestaurantDesignDTO>> {

		return this.api.post<Array<RestaurantDesignDTO>>("RestaurantDesign/saveRestaurantDesignList", restaurantDesignList);
	}

	activateRestaurantDesignById(id:number) : Observable<number>{

		return this.api.post<number>("RestaurantDesign/activateRestaurantDesignById", id);
	}

	activateRestaurantDesign(restaurantDesignDTO:RestaurantDesignDTO) : Observable<RestaurantDesignDTO> {

		return this.api.post<RestaurantDesignDTO>("RestaurantDesign/activateRestaurantDesign", restaurantDesignDTO);
	}

	activateRestaurantDesignList(restaurantDesignList:Array<RestaurantDesignDTO>) : Observable<Array<RestaurantDesignDTO>> {

		return this.api.post<Array<RestaurantDesignDTO>>("RestaurantDesign/activateRestaurantDesignList", restaurantDesignList);
	}

	deactivateRestaurantDesignById(id:number) : Observable<number> {

		return this.api.post<number>("RestaurantDesign/deactivateRestaurantDesignById", id);
	}

	deactivateRestaurantDesign(restaurantDesignDTO:RestaurantDesignDTO) : Observable<RestaurantDesignDTO> {

		return this.api.post<RestaurantDesignDTO>("RestaurantDesign/deactivateRestaurantDesign", restaurantDesignDTO);
	}

	deactivateRestaurantDesignList(restaurantDesignList:Array<RestaurantDesignDTO>) : Observable<Array<RestaurantDesignDTO>> {

		return this.api.post<Array<RestaurantDesignDTO>>("RestaurantDesign/deactivateRestaurantDesignList", restaurantDesignList);
	}
}