import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {TcodeStatusDTO} from '../dto/tcode-status-dto';

@Injectable()
export class BaseTcodeStatusService {

	constructor(public api:Api) {

	}

	getTcodeStatusById(id:number) : Observable<TcodeStatusDTO> {

		return this.api.post<TcodeStatusDTO>("TcodeStatus/getTcodeStatusById", id);
	}

	getTcodeStatus(tcodeStatusDTO:TcodeStatusDTO) : Observable<TcodeStatusDTO> {

		return this.api.post<TcodeStatusDTO>("TcodeStatus/getTcodeStatus", tcodeStatusDTO);
	}

	getTcodeStatusList() : Observable<TcodeStatusDTO[]> {

		return this.api.get<TcodeStatusDTO[]>("TcodeStatus/getTcodeStatusList");
	}

	getTcodeStatusMap() : Observable<Map<number, TcodeStatusDTO>> {

		return this.api.get<Map<number, TcodeStatusDTO>>("TcodeStatus/getTcodeStatusMap");
	}

	searchTcodeStatusList(tcodeStatusDTO:TcodeStatusDTO) : Observable<Array<TcodeStatusDTO>> {

		return this.api.post<Array<TcodeStatusDTO>>("TcodeStatus/searchTcodeStatusList", tcodeStatusDTO);
	}

	searchTcodeStatusMap(tcodeStatusDTO:TcodeStatusDTO) : Observable<Map<number, TcodeStatusDTO>> {

		return this.api.post<Map<number, TcodeStatusDTO>>("TcodeStatus/searchTcodeStatusMap", tcodeStatusDTO);
	}

	addTcodeStatus(tcodeStatusDTO:TcodeStatusDTO) : Observable<TcodeStatusDTO> {

		return this.api.post<TcodeStatusDTO>("TcodeStatus/addTcodeStatus", tcodeStatusDTO);
	}

	addTcodeStatusList(tcodeStatusList:Array<TcodeStatusDTO>) : Observable<Array<TcodeStatusDTO>> {

		return this.api.post<Array<TcodeStatusDTO>>("TcodeStatus/addTcodeStatusList", tcodeStatusList);
	}

	updateTcodeStatus(tcodeStatusDTO:TcodeStatusDTO) : Observable<TcodeStatusDTO> {

		return this.api.post<TcodeStatusDTO>("TcodeStatus/updateTcodeStatus", tcodeStatusDTO);
	}

	updateTcodeStatusList(tcodeStatusList:Array<TcodeStatusDTO>) : Observable<Array<TcodeStatusDTO>> {

		return this.api.post<Array<TcodeStatusDTO>>("TcodeStatus/updateTcodeStatusList", tcodeStatusList);
	}

	saveTcodeStatus(tcodeStatusDTO:TcodeStatusDTO) : Observable<TcodeStatusDTO> {

		return this.api.post<TcodeStatusDTO>("TcodeStatus/saveTcodeStatus", tcodeStatusDTO);
	}

	saveTcodeStatusList(tcodeStatusList:Array<TcodeStatusDTO>) : Observable<Array<TcodeStatusDTO>> {

		return this.api.post<Array<TcodeStatusDTO>>("TcodeStatus/saveTcodeStatusList", tcodeStatusList);
	}

	activateTcodeStatusById(id:number) : Observable<number>{

		return this.api.post<number>("TcodeStatus/activateTcodeStatusById", id);
	}

	activateTcodeStatus(tcodeStatusDTO:TcodeStatusDTO) : Observable<TcodeStatusDTO> {

		return this.api.post<TcodeStatusDTO>("TcodeStatus/activateTcodeStatus", tcodeStatusDTO);
	}

	activateTcodeStatusList(tcodeStatusList:Array<TcodeStatusDTO>) : Observable<Array<TcodeStatusDTO>> {

		return this.api.post<Array<TcodeStatusDTO>>("TcodeStatus/activateTcodeStatusList", tcodeStatusList);
	}

	deactivateTcodeStatusById(id:number) : Observable<number> {

		return this.api.post<number>("TcodeStatus/deactivateTcodeStatusById", id);
	}

	deactivateTcodeStatus(tcodeStatusDTO:TcodeStatusDTO) : Observable<TcodeStatusDTO> {

		return this.api.post<TcodeStatusDTO>("TcodeStatus/deactivateTcodeStatus", tcodeStatusDTO);
	}

	deactivateTcodeStatusList(tcodeStatusList:Array<TcodeStatusDTO>) : Observable<Array<TcodeStatusDTO>> {

		return this.api.post<Array<TcodeStatusDTO>>("TcodeStatus/deactivateTcodeStatusList", tcodeStatusList);
	}
}