import {RestaurantDestinationDTO} from '../dto/restaurant-destination-dto';

export class BaseTcodeRestDestinationDTO {

	id:number;
	englishName:string;
	arabicName:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;
	isOther:boolean;

	restaurantDestinationList:Array<RestaurantDestinationDTO> = new Array<RestaurantDestinationDTO>();
}