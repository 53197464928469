import {UserDTO} from '../dto/user-dto';
import {LoginHistDTO} from '../dto/login-hist-dto';

export class BaseDeviceDTO {

	id:number;
	userDTO:UserDTO = new UserDTO();
	token:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	loginHistList:Array<LoginHistDTO> = new Array<LoginHistDTO>();
}