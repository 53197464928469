import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {CustomerCuisinesDTO} from '../dto/customer-cuisines-dto';
import {CustomerDTO} from '../dto/customer-dto';
import {TcodeCuisineDTO} from '../dto/tcode-cuisine-dto';

@Injectable()
export class BaseCustomerCuisinesService {

	constructor(public api:Api) {

	}

	getCustomerCuisinesById(id:number) : Observable<CustomerCuisinesDTO> {

		return this.api.post<CustomerCuisinesDTO>("CustomerCuisines/getCustomerCuisinesById", id);
	}

	getCustomerCuisines(customerCuisinesDTO:CustomerCuisinesDTO) : Observable<CustomerCuisinesDTO> {

		return this.api.post<CustomerCuisinesDTO>("CustomerCuisines/getCustomerCuisines", customerCuisinesDTO);
	}

	getCustomerCuisinessByCustomerId(customerDTO:CustomerDTO) : Observable<CustomerDTO> {

		return this.api.post<CustomerDTO>("CustomerCuisines/getCustomerCuisinessByCustomerId", customerDTO);
	}

	getCustomerCuisinesListByCustomerId(customerId:number) : Observable<Array<CustomerCuisinesDTO>> {

		return this.api.post<Array<CustomerCuisinesDTO>>("CustomerCuisines/getCustomerCuisinesListByCustomerId", customerId);
	}

	getCustomerCuisinesMapByCustomerId(customerId:number) : Observable<Map<number, CustomerCuisinesDTO>>  {

		return this.api.post<Map<number, CustomerCuisinesDTO>>("CustomerCuisines/getCustomerCuisinesMapByCustomerId", customerId);
	}

	getCustomerCuisinessByCuisineId(tcodeCuisineDTO:TcodeCuisineDTO) : Observable<TcodeCuisineDTO> {

		return this.api.post<TcodeCuisineDTO>("CustomerCuisines/getCustomerCuisinessByCuisineId", tcodeCuisineDTO);
	}

	getCustomerCuisinesListByCuisineId(cuisineId:number) : Observable<Array<CustomerCuisinesDTO>> {

		return this.api.post<Array<CustomerCuisinesDTO>>("CustomerCuisines/getCustomerCuisinesListByCuisineId", cuisineId);
	}

	getCustomerCuisinesMapByCuisineId(cuisineId:number) : Observable<Map<number, CustomerCuisinesDTO>>  {

		return this.api.post<Map<number, CustomerCuisinesDTO>>("CustomerCuisines/getCustomerCuisinesMapByCuisineId", cuisineId);
	}

	getCustomerCuisinesList() : Observable<CustomerCuisinesDTO[]> {

		return this.api.get<CustomerCuisinesDTO[]>("CustomerCuisines/getCustomerCuisinesList");
	}

	getCustomerCuisinesMap() : Observable<Map<number, CustomerCuisinesDTO>> {

		return this.api.get<Map<number, CustomerCuisinesDTO>>("CustomerCuisines/getCustomerCuisinesMap");
	}

	searchCustomerCuisinesList(customerCuisinesDTO:CustomerCuisinesDTO) : Observable<Array<CustomerCuisinesDTO>> {

		return this.api.post<Array<CustomerCuisinesDTO>>("CustomerCuisines/searchCustomerCuisinesList", customerCuisinesDTO);
	}

	searchCustomerCuisinesMap(customerCuisinesDTO:CustomerCuisinesDTO) : Observable<Map<number, CustomerCuisinesDTO>> {

		return this.api.post<Map<number, CustomerCuisinesDTO>>("CustomerCuisines/searchCustomerCuisinesMap", customerCuisinesDTO);
	}

	addCustomerCuisines(customerCuisinesDTO:CustomerCuisinesDTO) : Observable<CustomerCuisinesDTO> {

		return this.api.post<CustomerCuisinesDTO>("CustomerCuisines/addCustomerCuisines", customerCuisinesDTO);
	}

	addCustomerCuisinesList(customerCuisinesList:Array<CustomerCuisinesDTO>) : Observable<Array<CustomerCuisinesDTO>> {

		return this.api.post<Array<CustomerCuisinesDTO>>("CustomerCuisines/addCustomerCuisinesList", customerCuisinesList);
	}

	updateCustomerCuisines(customerCuisinesDTO:CustomerCuisinesDTO) : Observable<CustomerCuisinesDTO> {

		return this.api.post<CustomerCuisinesDTO>("CustomerCuisines/updateCustomerCuisines", customerCuisinesDTO);
	}

	updateCustomerCuisinesList(customerCuisinesList:Array<CustomerCuisinesDTO>) : Observable<Array<CustomerCuisinesDTO>> {

		return this.api.post<Array<CustomerCuisinesDTO>>("CustomerCuisines/updateCustomerCuisinesList", customerCuisinesList);
	}

	saveCustomerCuisines(customerCuisinesDTO:CustomerCuisinesDTO) : Observable<CustomerCuisinesDTO> {

		return this.api.post<CustomerCuisinesDTO>("CustomerCuisines/saveCustomerCuisines", customerCuisinesDTO);
	}

	saveCustomerCuisinesList(customerCuisinesList:Array<CustomerCuisinesDTO>) : Observable<Array<CustomerCuisinesDTO>> {

		return this.api.post<Array<CustomerCuisinesDTO>>("CustomerCuisines/saveCustomerCuisinesList", customerCuisinesList);
	}

	activateCustomerCuisinesById(id:number) : Observable<number>{

		return this.api.post<number>("CustomerCuisines/activateCustomerCuisinesById", id);
	}

	activateCustomerCuisines(customerCuisinesDTO:CustomerCuisinesDTO) : Observable<CustomerCuisinesDTO> {

		return this.api.post<CustomerCuisinesDTO>("CustomerCuisines/activateCustomerCuisines", customerCuisinesDTO);
	}

	activateCustomerCuisinesList(customerCuisinesList:Array<CustomerCuisinesDTO>) : Observable<Array<CustomerCuisinesDTO>> {

		return this.api.post<Array<CustomerCuisinesDTO>>("CustomerCuisines/activateCustomerCuisinesList", customerCuisinesList);
	}

	deactivateCustomerCuisinesById(id:number) : Observable<number> {

		return this.api.post<number>("CustomerCuisines/deactivateCustomerCuisinesById", id);
	}

	deactivateCustomerCuisines(customerCuisinesDTO:CustomerCuisinesDTO) : Observable<CustomerCuisinesDTO> {

		return this.api.post<CustomerCuisinesDTO>("CustomerCuisines/deactivateCustomerCuisines", customerCuisinesDTO);
	}

	deactivateCustomerCuisinesList(customerCuisinesList:Array<CustomerCuisinesDTO>) : Observable<Array<CustomerCuisinesDTO>> {

		return this.api.post<Array<CustomerCuisinesDTO>>("CustomerCuisines/deactivateCustomerCuisinesList", customerCuisinesList);
	}
}