import {RestaurantDesignDTO} from '../dto/restaurant-design-dto';

export class BaseTcodeRestDesignDTO {

	id:number;
	englishName:string;
	arabicName:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	restaurantDesignList:Array<RestaurantDesignDTO> = new Array<RestaurantDesignDTO>();
}