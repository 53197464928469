import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserDTO } from '../../dto/user-dto';
import { AdminService } from '../../services/admin.service';
import { CommonComponent } from '../../components/common.component';

import {Location} from "@angular/common";
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent extends CommonComponent{

  userDTO:UserDTO = new UserDTO();

  isLoggedIn:boolean =false ;

  public constructor(public adminService:AdminService ,private location:Location , private router: Router , 
                  	public translateService: TranslateService ,private data: LoginService) {
    super(translateService);

    this.data.loggedInStatus.subscribe(isLoggedIn => this.isLoggedIn = isLoggedIn)

    // https://stackoverflow.com/questions/34331478/angular-redirect-to-login-page 
    
    if (!this.isLoggedIn) {//authService.isAuthenticated()
      this.location.replaceState('/'); // clears browser history so they can't navigate with back button
      this.router.navigate(['login']);
    }

  }

  /**
   * 
   * Check this maybe usefaull to manage login 
   * 
   * 
   * https://jasonwatmore.com/post/2020/04/28/angular-9-user-registration-and-login-example-tutorial
   * 
   * 
   * https://ultimatecourses.com/blog/component-events-event-emitter-output-angular-2
   * 
   *  https://dzone.com/articles/understanding-output-and-eventemitter-in-angular
   * 
   */
  
  public doAdminLogin(){
    this.userDTO.email = this.userDTO.email.toLowerCase();

    this.adminService.loginAdmin(this.userDTO).subscribe((userDTO:UserDTO)=> {
        if(userDTO!=null && userDTO.id!=0){
          // Very Important -->     https://fireship.io/lessons/sharing-data-between-angular-components-four-methods/
          this.data.setLoggedIn(true);
          this.data.setRoleID(userDTO.roleDTO.id);
          if(userDTO.supplierDTO.id !=null && userDTO.supplierDTO.id !=0){
            this.data.setSupplierId(userDTO.supplierDTO.id);
          }else{
            this.data.setSupplierId(null)
          }
          
          this.router.navigate(['dashboard']);
        }else{
          alert("Wrong password,please try again");
        }
		}, (error:any) => {
			alert("Could not login to admin panel");
		});  }

 }
