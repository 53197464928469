import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from '../base-service/api.service';
import { BaseOfferService } from '../base-service/base-offer.service';
import { OfferDTO } from '../dto/offer-dto';

@Injectable()
export class OfferService extends BaseOfferService{

	constructor(public api:Api) {
		super(api);
	}

	
	saveOffer(offerDTO:OfferDTO) : Observable<any> {

		return this.api.post<OfferDTO>("OfferService/saveOffer", offerDTO);
	}

}