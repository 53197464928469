import {RestaurantSpecialityDTO} from '../dto/restaurant-speciality-dto';

export class BaseTcodeSpecialityDTO {

	id:number;
	englishName:string;
	arabicName:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	restaurantSpecialityList:Array<RestaurantSpecialityDTO> = new Array<RestaurantSpecialityDTO>();
}