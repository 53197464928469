export class FadosConstants {
    static ADMIN_ROLE_ID = 1;
    static SUPPLIER_ROLE_ID = 2;
    static CUSTOMER_ROLE_ID = 3;
    static SUPPLIER_EMPLOYEE_ROLE_ID = 4;

    static RESTAURANT_SUPPLIER_TYPE_ID = 1;


	//Customer Status
	static USER_STATUS_VERIFICATION_REQUIRED = 1 
    static USER_STATUS_ACTIVE =2;
    static USER_STATUS_LOCKED = 3 ;
	
	//Supplier Status
    static SUPPLIER_STATUS_REGISTERED = 4;
    static SUPPLIER_STATUS_ACCEPTED = 5;
	static SUPPLIER_STATUS_REJECTED = 6;
	static SUPPLIER_STATUS_SUSPENDED:number = 7;
    
    /**
     * 
     * IMPORTANT NOTES ABOUT TITLES AND TERMS LIST
     * 
     */


    /* 
     * this hardcoded list is meant for release 1 due to limitation of time.
     * It is better to move this list to table in database where user can add/Edit offer terms
     * 
     * Currently they are Text senstive so before changing any text value here, please update exsiting records in DB with new text first
     * 
     * If new will need to be added, please add it to end of the array.
     * 
     * IMPORTANT NOTE: Remove an item from the array may result in issue with selecting the previouse data
     */
    
    static OFFERS_TERMS_LIST :Object[] =[   { id:1 , en: 'On Purchases 30 SAR And Above' , ar :'عند الشراء ب 30 ريال وأكثر'},
                                            { id:2 , en: 'On Purchases 50 SAR And Above' , ar :' عند الشراء ب 50 ريال وأكثر'},
                                            { id:3 , en: 'On Purchases 100 SAR And Above' , ar :'عند الشراء ب 100 ريال وأكثر'},
                                            { id:4 , en: 'On Purchases 120 SAR And Above' , ar :'عند الشراء ب 120 ريال وأكثر'}, 
                                            { id:5 , en: 'On Purchases 150 SAR And Above' , ar :'عند الشراء ب 150 ريال وأكثر'}, 
                                            { id:6 , en: 'On Purchases 200 SAR And Above' , ar :'عند الشراء ب 200 ريال وأكثر'}
                                ];


    static OFFERS_TIMING_TERMS_LIST :Object[] =[   
                                { id:1 , en: 'Lunch Menu Only' , ar :'قائمة الغداء فقط'},
                                { id:2 , en: 'Dinner Menu Only' , ar :'قائمة العشاء فقط'},
                                { id:3 , en: 'Breakfast Menu Only', ar :'قائمة الإفطار فقط'} ,
                                { id:4 , en: 'For All Menu' , ar :'كل قوائم الطعام'},      
                    ];
    /**
     * this hardcoded list is meant for release 1 due to limitation of time.
     * It is better to move this list to table in database where user can add/Edit offer titiles
     * 
     * If DB table is created, please make sure to add them in the same sequence number and order as below.
     */
    static OFFERS_TITILES_LIST  =  [{ id:1 , en: '5% Discount' , ar :'تخفيض 5%'},
                                    { id:2 , en: '10% Discount' , ar :'تخفيض 10%'},
                                    { id:3 , en: '15% Discount' , ar :'تخفيض 15%'},
                                    { id:4 , en: '20% Discount' , ar :'تخفيض 20%'},
                                    { id:5 , en: '25% Discount' , ar :'تخفيض 25%'},
                                    { id:6 , en: '30% Discount' , ar :'تخفيض 30%'},
                                    { id:7 , en: '35% Discount' , ar :'تخفيض 35%'},
                                    { id:8 , en: '40% Discount' , ar :'تخفيض 40%'},
                                    { id:9 , en: '45% Discount' , ar :'تخفيض 45%'},
                                    { id:10 , en: '50% Discount' , ar :'تخفيض 50%'},
                                    { id:11 , en:  '10 SAR OFF' , ar :'خصم 10 ريال'},
                                    { id:12 , en: '15 SAR OFF' , ar :'خصم 15 ريال'},
                                    { id:13 , en: '20 SAR OFF' , ar :'خصم 20 ريال'},
                                    { id:14 , en: 'One Free Soft Drink' , ar :'مشروب غازي مجاني'},
                                    { id:15 , en: 'One Free Fresh Juice' , ar :'عصير طازج مجاني'},
                                    { id:16 , en: 'One Free Soft Drink And One Appetizer' , ar :'مشروب غازي وطبق مقبلات مجاني'},
                                    { id:17 , en: 'One Free Appetizer' , ar :'صحن مقبلات مجاني'},
                                ];
}