import {SupplierDTO} from '../dto/supplier-dto';
import {CustomerDTO} from '../dto/customer-dto';

export class BaseTcodeStatusDTO {

	id:number;
	englishName:string;
	arabicName:string;
	description:string;
	category:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	supplierList:Array<SupplierDTO> = new Array<SupplierDTO>();
	customerList:Array<CustomerDTO> = new Array<CustomerDTO>();
}