import {SupplierDTO} from '../dto/supplier-dto';

export class BaseTcodeSupplierTypeDTO {

	id:number;
	englishName:string;
	arabicName:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	supplierList:Array<SupplierDTO> = new Array<SupplierDTO>();
}