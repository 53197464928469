import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {TcodeSpecialityDTO} from '../dto/tcode-speciality-dto';

@Injectable()
export class BaseTcodeSpecialityService {

	constructor(public api:Api) {

	}

	getTcodeSpecialityById(id:number) : Observable<TcodeSpecialityDTO> {

		return this.api.post<TcodeSpecialityDTO>("TcodeSpeciality/getTcodeSpecialityById", id);
	}

	getTcodeSpeciality(tcodeSpecialityDTO:TcodeSpecialityDTO) : Observable<TcodeSpecialityDTO> {

		return this.api.post<TcodeSpecialityDTO>("TcodeSpeciality/getTcodeSpeciality", tcodeSpecialityDTO);
	}

	getTcodeSpecialityList() : Observable<TcodeSpecialityDTO[]> {

		return this.api.get<TcodeSpecialityDTO[]>("TcodeSpeciality/getTcodeSpecialityList");
	}

	getTcodeSpecialityMap() : Observable<Map<number, TcodeSpecialityDTO>> {

		return this.api.get<Map<number, TcodeSpecialityDTO>>("TcodeSpeciality/getTcodeSpecialityMap");
	}

	searchTcodeSpecialityList(tcodeSpecialityDTO:TcodeSpecialityDTO) : Observable<Array<TcodeSpecialityDTO>> {

		return this.api.post<Array<TcodeSpecialityDTO>>("TcodeSpeciality/searchTcodeSpecialityList", tcodeSpecialityDTO);
	}

	searchTcodeSpecialityMap(tcodeSpecialityDTO:TcodeSpecialityDTO) : Observable<Map<number, TcodeSpecialityDTO>> {

		return this.api.post<Map<number, TcodeSpecialityDTO>>("TcodeSpeciality/searchTcodeSpecialityMap", tcodeSpecialityDTO);
	}

	addTcodeSpeciality(tcodeSpecialityDTO:TcodeSpecialityDTO) : Observable<TcodeSpecialityDTO> {

		return this.api.post<TcodeSpecialityDTO>("TcodeSpeciality/addTcodeSpeciality", tcodeSpecialityDTO);
	}

	addTcodeSpecialityList(tcodeSpecialityList:Array<TcodeSpecialityDTO>) : Observable<Array<TcodeSpecialityDTO>> {

		return this.api.post<Array<TcodeSpecialityDTO>>("TcodeSpeciality/addTcodeSpecialityList", tcodeSpecialityList);
	}

	updateTcodeSpeciality(tcodeSpecialityDTO:TcodeSpecialityDTO) : Observable<TcodeSpecialityDTO> {

		return this.api.post<TcodeSpecialityDTO>("TcodeSpeciality/updateTcodeSpeciality", tcodeSpecialityDTO);
	}

	updateTcodeSpecialityList(tcodeSpecialityList:Array<TcodeSpecialityDTO>) : Observable<Array<TcodeSpecialityDTO>> {

		return this.api.post<Array<TcodeSpecialityDTO>>("TcodeSpeciality/updateTcodeSpecialityList", tcodeSpecialityList);
	}

	saveTcodeSpeciality(tcodeSpecialityDTO:TcodeSpecialityDTO) : Observable<TcodeSpecialityDTO> {

		return this.api.post<TcodeSpecialityDTO>("TcodeSpeciality/saveTcodeSpeciality", tcodeSpecialityDTO);
	}

	saveTcodeSpecialityList(tcodeSpecialityList:Array<TcodeSpecialityDTO>) : Observable<Array<TcodeSpecialityDTO>> {

		return this.api.post<Array<TcodeSpecialityDTO>>("TcodeSpeciality/saveTcodeSpecialityList", tcodeSpecialityList);
	}

	activateTcodeSpecialityById(id:number) : Observable<number>{

		return this.api.post<number>("TcodeSpeciality/activateTcodeSpecialityById", id);
	}

	activateTcodeSpeciality(tcodeSpecialityDTO:TcodeSpecialityDTO) : Observable<TcodeSpecialityDTO> {

		return this.api.post<TcodeSpecialityDTO>("TcodeSpeciality/activateTcodeSpeciality", tcodeSpecialityDTO);
	}

	activateTcodeSpecialityList(tcodeSpecialityList:Array<TcodeSpecialityDTO>) : Observable<Array<TcodeSpecialityDTO>> {

		return this.api.post<Array<TcodeSpecialityDTO>>("TcodeSpeciality/activateTcodeSpecialityList", tcodeSpecialityList);
	}

	deactivateTcodeSpecialityById(id:number) : Observable<number> {

		return this.api.post<number>("TcodeSpeciality/deactivateTcodeSpecialityById", id);
	}

	deactivateTcodeSpeciality(tcodeSpecialityDTO:TcodeSpecialityDTO) : Observable<TcodeSpecialityDTO> {

		return this.api.post<TcodeSpecialityDTO>("TcodeSpeciality/deactivateTcodeSpeciality", tcodeSpecialityDTO);
	}

	deactivateTcodeSpecialityList(tcodeSpecialityList:Array<TcodeSpecialityDTO>) : Observable<Array<TcodeSpecialityDTO>> {

		return this.api.post<Array<TcodeSpecialityDTO>>("TcodeSpeciality/deactivateTcodeSpecialityList", tcodeSpecialityList);
	}
}