import {SupplierDTO} from '../dto/supplier-dto';

export class BaseRestaurantRateDTO {

	id:number;
	supplierDTO:SupplierDTO = new SupplierDTO();
	customerId:number;
	rate:number;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;
}