import {SupplierDTO} from '../dto/supplier-dto';
import {OfferDTO} from '../dto/offer-dto';
import {RestaurantDestinationDTO} from '../dto/restaurant-destination-dto';
import {RestaurantEnvironmentDTO} from '../dto/restaurant-environment-dto';
import {RestaurantSpecialityDTO} from '../dto/restaurant-speciality-dto';
import {RestReturnCustomerDTO} from '../dto/rest-return-customer-dto';
import {RestaurantDesignDTO} from '../dto/restaurant-design-dto';
import {RestaurantCuisinesDTO} from '../dto/restaurant-cuisines-dto';

export class BaseRestaurantDTO extends SupplierDTO {

	isHalal:boolean;
	takeaway:boolean;
	kidsFriendly:boolean;
	groupsFriendly:boolean;
	delivery:boolean;
	budget:number;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	offerList:Array<OfferDTO> = new Array<OfferDTO>();
	restaurantDestinationList:Array<RestaurantDestinationDTO> = new Array<RestaurantDestinationDTO>();
	restaurantEnvironmentList:Array<RestaurantEnvironmentDTO> = new Array<RestaurantEnvironmentDTO>();
	restaurantSpecialityList:Array<RestaurantSpecialityDTO> = new Array<RestaurantSpecialityDTO>();
	restReturnCustomerList:Array<RestReturnCustomerDTO> = new Array<RestReturnCustomerDTO>();
	restaurantDesignList:Array<RestaurantDesignDTO> = new Array<RestaurantDesignDTO>();
	restaurantCuisinesList:Array<RestaurantCuisinesDTO> = new Array<RestaurantCuisinesDTO>();
}