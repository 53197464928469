import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {TcodeSupplierTypeDTO} from '../dto/tcode-supplier-type-dto';

@Injectable()
export class BaseTcodeSupplierTypeService {

	constructor(public api:Api) {

	}

	getTcodeSupplierTypeById(id:number) : Observable<TcodeSupplierTypeDTO> {

		return this.api.post<TcodeSupplierTypeDTO>("TcodeSupplierType/getTcodeSupplierTypeById", id);
	}

	getTcodeSupplierType(tcodeSupplierTypeDTO:TcodeSupplierTypeDTO) : Observable<TcodeSupplierTypeDTO> {

		return this.api.post<TcodeSupplierTypeDTO>("TcodeSupplierType/getTcodeSupplierType", tcodeSupplierTypeDTO);
	}

	getTcodeSupplierTypeList() : Observable<TcodeSupplierTypeDTO[]> {

		return this.api.get<TcodeSupplierTypeDTO[]>("TcodeSupplierType/getTcodeSupplierTypeList");
	}

	getTcodeSupplierTypeMap() : Observable<Map<number, TcodeSupplierTypeDTO>> {

		return this.api.get<Map<number, TcodeSupplierTypeDTO>>("TcodeSupplierType/getTcodeSupplierTypeMap");
	}

	searchTcodeSupplierTypeList(tcodeSupplierTypeDTO:TcodeSupplierTypeDTO) : Observable<Array<TcodeSupplierTypeDTO>> {

		return this.api.post<Array<TcodeSupplierTypeDTO>>("TcodeSupplierType/searchTcodeSupplierTypeList", tcodeSupplierTypeDTO);
	}

	searchTcodeSupplierTypeMap(tcodeSupplierTypeDTO:TcodeSupplierTypeDTO) : Observable<Map<number, TcodeSupplierTypeDTO>> {

		return this.api.post<Map<number, TcodeSupplierTypeDTO>>("TcodeSupplierType/searchTcodeSupplierTypeMap", tcodeSupplierTypeDTO);
	}

	addTcodeSupplierType(tcodeSupplierTypeDTO:TcodeSupplierTypeDTO) : Observable<TcodeSupplierTypeDTO> {

		return this.api.post<TcodeSupplierTypeDTO>("TcodeSupplierType/addTcodeSupplierType", tcodeSupplierTypeDTO);
	}

	addTcodeSupplierTypeList(tcodeSupplierTypeList:Array<TcodeSupplierTypeDTO>) : Observable<Array<TcodeSupplierTypeDTO>> {

		return this.api.post<Array<TcodeSupplierTypeDTO>>("TcodeSupplierType/addTcodeSupplierTypeList", tcodeSupplierTypeList);
	}

	updateTcodeSupplierType(tcodeSupplierTypeDTO:TcodeSupplierTypeDTO) : Observable<TcodeSupplierTypeDTO> {

		return this.api.post<TcodeSupplierTypeDTO>("TcodeSupplierType/updateTcodeSupplierType", tcodeSupplierTypeDTO);
	}

	updateTcodeSupplierTypeList(tcodeSupplierTypeList:Array<TcodeSupplierTypeDTO>) : Observable<Array<TcodeSupplierTypeDTO>> {

		return this.api.post<Array<TcodeSupplierTypeDTO>>("TcodeSupplierType/updateTcodeSupplierTypeList", tcodeSupplierTypeList);
	}

	saveTcodeSupplierType(tcodeSupplierTypeDTO:TcodeSupplierTypeDTO) : Observable<TcodeSupplierTypeDTO> {

		return this.api.post<TcodeSupplierTypeDTO>("TcodeSupplierType/saveTcodeSupplierType", tcodeSupplierTypeDTO);
	}

	saveTcodeSupplierTypeList(tcodeSupplierTypeList:Array<TcodeSupplierTypeDTO>) : Observable<Array<TcodeSupplierTypeDTO>> {

		return this.api.post<Array<TcodeSupplierTypeDTO>>("TcodeSupplierType/saveTcodeSupplierTypeList", tcodeSupplierTypeList);
	}

	activateTcodeSupplierTypeById(id:number) : Observable<number>{

		return this.api.post<number>("TcodeSupplierType/activateTcodeSupplierTypeById", id);
	}

	activateTcodeSupplierType(tcodeSupplierTypeDTO:TcodeSupplierTypeDTO) : Observable<TcodeSupplierTypeDTO> {

		return this.api.post<TcodeSupplierTypeDTO>("TcodeSupplierType/activateTcodeSupplierType", tcodeSupplierTypeDTO);
	}

	activateTcodeSupplierTypeList(tcodeSupplierTypeList:Array<TcodeSupplierTypeDTO>) : Observable<Array<TcodeSupplierTypeDTO>> {

		return this.api.post<Array<TcodeSupplierTypeDTO>>("TcodeSupplierType/activateTcodeSupplierTypeList", tcodeSupplierTypeList);
	}

	deactivateTcodeSupplierTypeById(id:number) : Observable<number> {

		return this.api.post<number>("TcodeSupplierType/deactivateTcodeSupplierTypeById", id);
	}

	deactivateTcodeSupplierType(tcodeSupplierTypeDTO:TcodeSupplierTypeDTO) : Observable<TcodeSupplierTypeDTO> {

		return this.api.post<TcodeSupplierTypeDTO>("TcodeSupplierType/deactivateTcodeSupplierType", tcodeSupplierTypeDTO);
	}

	deactivateTcodeSupplierTypeList(tcodeSupplierTypeList:Array<TcodeSupplierTypeDTO>) : Observable<Array<TcodeSupplierTypeDTO>> {

		return this.api.post<Array<TcodeSupplierTypeDTO>>("TcodeSupplierType/deactivateTcodeSupplierTypeList", tcodeSupplierTypeList);
	}
}