import {Component, TemplateRef, ViewChild, OnInit} from '@angular/core';
import { adminNavItems, supplierNavItems, supplierEmployeeNavItems } from '../../_nav';

import { SharedService } from '../../services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LoginService } from '../../services/login.service';
import { Router } from '@angular/router';
import {Location} from "@angular/common";
import { OfferDTO } from '../../dto/offer-dto';
import { FadosConstants } from '../../fados.constants';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit{
  public sidebarMinimized = false;
  public adminNavItems = adminNavItems;
  public supplierNavItems = supplierNavItems;
  public supplierEmployeeNavItems = supplierEmployeeNavItems;

  adminRoleId = FadosConstants.ADMIN_ROLE_ID;
  supplierRoleId = FadosConstants.SUPPLIER_ROLE_ID;
  supplierEmployeeRoleId = FadosConstants.SUPPLIER_EMPLOYEE_ROLE_ID;

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  
  //success:string;
  //info:string;
  //error:string;
  alert:any= new Object();
  confirmTitle:string;
  confirmMessage:string;
  confirmAction:Function;
  confirmDataAction:Function
  offerDTO: OfferDTO ;

  message:string;

  isLoggedIn: boolean =false;
  loggedInSupplierId: number = 0;
  loggedInRoleId: number = 0;

  @ViewChild("confirmModal", {static: true}) confirmModal:TemplateRef<any>;
  @ViewChild("confirmDisplayedDataModal", {static: true}) confirmDisplayedDataModal:TemplateRef<any>;


  
  confirmModalRef: BsModalRef;
  confirmDisplayedDataModalRef: BsModalRef;


  ngOnInit() {
    this.data.loggedInStatus.subscribe(isLoggedIn => this.isLoggedIn = isLoggedIn);
    this.data.supplierId.subscribe(loggedInSupplierId => this.loggedInSupplierId = loggedInSupplierId);
    this.data.roleId.subscribe(loggedInRoleId => this.loggedInRoleId = loggedInRoleId);

    if (!this.isLoggedIn) {
      this.location.replaceState('/'); // clears browser history so they can't navigate with back button
      this.router.navigate(['login']);
    }

    //https://fireship.io/lessons/sharing-data-between-angular-components-four-methods/

    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
  }
  constructor(public translateService: TranslateService, bsModalService: BsModalService , private data: LoginService ,private location:Location ,private router: Router) {

    SharedService.confirmModelSubject.subscribe((data:any)=>{
      if(data != null){
        translateService.get([data.title, data.message]).subscribe((values:string)=>{
          this.confirmTitle = values[data.title];
          this.confirmMessage = values[data.message];
          this.confirmAction = data.function;
          this.confirmModalRef = bsModalService.show(this.confirmModal);
        });
      }
    });

    SharedService.confirmDisplayDataModelSubject.subscribe((data:any)=>{
      if(data != null){
        translateService.get([data.title, data.message]).subscribe((values:string)=>{
          this.confirmTitle = values[data.title];
          this.confirmMessage = values[data.message];
          this.offerDTO = data.offerDTO;
          this.confirmDataAction = data.function;
          this.confirmDisplayedDataModalRef = bsModalService.show(this.confirmDisplayedDataModal);
        });
      }
    });

    SharedService.successMessageSubject.subscribe((success:string)=>{
      if(success != null){
        translateService.get(success).subscribe((success:string)=>{
          this.alert = { "class":"alert-success" , "content":success} ;
          setTimeout(_=>{
            this.alert = { "class":"alert-success" , "content":null} ;
          }, 6000);
        });
      }
    });

 

    SharedService.infoMessageSubject.subscribe((info:string)=>{
      if(info != null){
        translateService.get(info).subscribe((info:string)=>{
          this.alert = { "class":"alert-info" , "content":info} ;
          setTimeout(_=>{
            this.alert = { "class":"alert-info" , "content":null} ;
          }, 6000);
        });
      }
    });

 

    SharedService.errorMessageSubject.subscribe((error:string)=>{
     if(error != null){
        translateService.get(error).subscribe((error:string)=>{
          this.alert = { "class":"alert-danger" , "content":error} ;
          setTimeout(_=>{
            this.alert = { "class":"alert-danger" , "content":null} ;
          }, 9000);
        });
      }
    });
  }


  proceedUserAction(){
    this.confirmAction.call(null);
    this.confirmModalRef.hide();
  }

  confirmDataProceedUserAction(){
    this.confirmDataAction.call(null);
    this.confirmDisplayedDataModalRef.hide();
  }

  logout(){
    this.isLoggedIn= false;
    this.location.replaceState('/'); // clears browser history so they can't navigate with back button

    this.router.navigate(['login']);

  }
}
