import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {RoleDTO} from '../dto/role-dto';

@Injectable()
export class BaseRoleService {

	constructor(public api:Api) {

	}

	getRoleById(id:number) : Observable<RoleDTO> {

		return this.api.post<RoleDTO>("Role/getRoleById", id);
	}

	getRole(roleDTO:RoleDTO) : Observable<RoleDTO> {

		return this.api.post<RoleDTO>("Role/getRole", roleDTO);
	}

	getRoleList() : Observable<RoleDTO[]> {

		return this.api.get<RoleDTO[]>("Role/getRoleList");
	}

	getRoleMap() : Observable<Map<number, RoleDTO>> {

		return this.api.get<Map<number, RoleDTO>>("Role/getRoleMap");
	}

	searchRoleList(roleDTO:RoleDTO) : Observable<Array<RoleDTO>> {

		return this.api.post<Array<RoleDTO>>("Role/searchRoleList", roleDTO);
	}

	searchRoleMap(roleDTO:RoleDTO) : Observable<Map<number, RoleDTO>> {

		return this.api.post<Map<number, RoleDTO>>("Role/searchRoleMap", roleDTO);
	}

	addRole(roleDTO:RoleDTO) : Observable<RoleDTO> {

		return this.api.post<RoleDTO>("Role/addRole", roleDTO);
	}

	addRoleList(roleList:Array<RoleDTO>) : Observable<Array<RoleDTO>> {

		return this.api.post<Array<RoleDTO>>("Role/addRoleList", roleList);
	}

	updateRole(roleDTO:RoleDTO) : Observable<RoleDTO> {

		return this.api.post<RoleDTO>("Role/updateRole", roleDTO);
	}

	updateRoleList(roleList:Array<RoleDTO>) : Observable<Array<RoleDTO>> {

		return this.api.post<Array<RoleDTO>>("Role/updateRoleList", roleList);
	}

	saveRole(roleDTO:RoleDTO) : Observable<RoleDTO> {

		return this.api.post<RoleDTO>("Role/saveRole", roleDTO);
	}

	saveRoleList(roleList:Array<RoleDTO>) : Observable<Array<RoleDTO>> {

		return this.api.post<Array<RoleDTO>>("Role/saveRoleList", roleList);
	}

	activateRoleById(id:number) : Observable<number>{

		return this.api.post<number>("Role/activateRoleById", id);
	}

	activateRole(roleDTO:RoleDTO) : Observable<RoleDTO> {

		return this.api.post<RoleDTO>("Role/activateRole", roleDTO);
	}

	activateRoleList(roleList:Array<RoleDTO>) : Observable<Array<RoleDTO>> {

		return this.api.post<Array<RoleDTO>>("Role/activateRoleList", roleList);
	}

	deactivateRoleById(id:number) : Observable<number> {

		return this.api.post<number>("Role/deactivateRoleById", id);
	}

	deactivateRole(roleDTO:RoleDTO) : Observable<RoleDTO> {

		return this.api.post<RoleDTO>("Role/deactivateRole", roleDTO);
	}

	deactivateRoleList(roleList:Array<RoleDTO>) : Observable<Array<RoleDTO>> {

		return this.api.post<Array<RoleDTO>>("Role/deactivateRoleList", roleList);
	}
}