import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from '../base-service/api.service';
import { BaseCustomerService } from '../base-service/base-customer.service';
import { UserDTO } from '../dto/user-dto';

@Injectable()
export class AdminService {

	constructor(public api:Api) {

	}

	loginAdmin(userDTO:UserDTO) : Observable<any> {

		return this.api.post<UserDTO>("AdminService/loginAdmin", userDTO);
	}

	getSupplierOffersStatistics(supplierId:number) : Observable<any> {

		return this.api.get<any>("AdminService/getSupplierOffersStatistics/"+supplierId);
	}

}