import { OnInit, HostListener } from "@angular/core";
import { SharedService } from "../services/shared.service";
import { TranslateService } from '@ngx-translate/core';
import { OfferDTO } from "../dto/offer-dto";

export class CommonComponent implements OnInit {

	
    mode:string = "list";
   
    loading:boolean = false;

    isCollapsed: boolean = false;
    iconCollapse: string = 'icon-arrow-up';
  
  constructor(public translateService:TranslateService) {
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
    this.isCollapsed = false;
    
  }


  ///// start s

  status: { isOpen: boolean } = { isOpen: false };
  disabled: boolean = false;
  isDropup: boolean = true;
  autoClose: boolean = false;

  items: string[] = [
    'The first choice!',
    'And another choice for you.',
    'but wait! A third!'
  ];


  ngOnDestroy () {
    this.status.isOpen = false;
  }

  onHidden(): void {
    console.log('Dropdown is hidden');
  }
  onShown(): void {
    console.log('Dropdown is shown');
  }
  isOpenChange(): void {
    console.log('Dropdown state is changed');
  }

  toggleDropdown($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.status.isOpen = !this.status.isOpen;
  }

  change(value: boolean): void {
    this.status.isOpen = value;
  }
  ///// END 

  collapsed(event: any): void {
    // console.log(event);
  }

  expanded(event: any): void {
    // console.log(event);
  }

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
    this.iconCollapse = this.isCollapsed ? 'icon-arrow-down' : 'icon-arrow-up';
  }

  ngOnInit(): void {
   
    this.isValidForm();
  }

  @HostListener('input', ['$event']) handleKeyDown(event) {
    this.validateElement(event.target);
  }

  @HostListener('document:keypress', ['$event']) handlekeypress(event) {
    this.validateNumber(event);
  }

  @HostListener('click', ['$event']) handleCLick(event) {
    setTimeout(() => {
      this.isValidForm();
    });
  }

  isValidForm() {

    var result = null;

    let elements:NodeListOf<Element> = document.querySelectorAll("input, textarea, select");

    for (let i = elements.length -1; i >= 0; i--) {

      const element:any = elements[i];

      var message = this.validateElement(element);

      if (message != null) {

        result = message;
      }
    }

    return result;
  }


  validateElement(element){

  if(element.value == null || element.value == "" )
    {

      if (element.classList.contains("required"))
      {
        return this.setMessage(element, element.getAttribute("placeholder") + " should not be empty " );
      }

      if(element.hasAttribute("requiredIfEmpty"))
      {
        let other = element.getAttribute("requiredIfEmpty");
        let otherElement:any = document.getElementById(other);

        if(otherElement.value == null || otherElement.value == ""  || otherElement.value.trim() == "" || otherElement.value == 0 )
        {
          if(element.value == null || element.value == "" ||element.value == 0 )
          {
            return this.setMessage(element, element.getAttribute("placeholder") + " or " + otherElement.getAttribute("placeholder") + " should not be empty" );
          }
        }
      }
    }

    else
    {

      if (element.classList.contains("name") && element.value.length <= 5)
      {
        return this.setMessage(element, element.getAttribute("placeholder") + " should be more than 5 character");
      }

      if (element.classList.contains("date") && element.value.match(/^(0?[1-9]|[12][0-9]|3[01])[-](0?[1-9]|1[012])[-]\d{4}$/) == null)
      {
        return this.setMessage(element, element.getAttribute("placeholder") + " should be with valid date format");
      }

      if(element.classList.contains("greaterThanToday"))
      {
        let date:Date = this.getDate(element.value);
        let today:Date = new Date();

        if(date < today )
        {
          return this.setMessage(element, element.getAttribute("placeholder") + " should be greater than today date " );
        }
      }

      if(element.hasAttribute("greater"))
      {
        let other = element.getAttribute("greater");
        let otherElement:any = document.getElementById(other);
        let date:Date = this.getDate(element.value);
        let otherDate:Date = this.getDate(otherElement.value);

        if(date.getTime() < otherDate.getTime() )
        {
          return this.setMessage(element, element.getAttribute("placeholder") + " should be greater than " + otherElement.getAttribute("placeholder") );
        }
      }

      if(element.hasAttribute("less"))
      {
        let other = element.getAttribute("less");
        let otherElement:any = document.getElementById(other);
        let date:Date = this.getDate(element.value);
        let otherDate:Date = this.getDate(otherElement.value);

        if(date.getTime() > otherDate.getTime() )
        {
          return this.setMessage(element, element.getAttribute("placeholder") + " should be less than " + otherElement.getAttribute("placeholder") );
        }
      }

      if (element.classList.contains("positive") && parseInt(element.value) <= 0)
      {
        return this.setMessage(element, element.getAttribute("placeholder") + " should be positive");
      }

      if (element.hasAttribute("greaterEqual"))
      {
        let value:any = element.getAttribute("greaterEqual");
        if(parseInt(element.value) < parseInt(value))
        {
          return this.setMessage(element, element.getAttribute("placeholder") + " cannot be less than " + value);
        }

      }

      if(element.hasAttribute('max') && Number(element.value) > Number(element.getAttribute('max')))
      {
        return this.setMessage(element, element.placeholder + ' should be less than ' + element.getAttribute('max'));
      }

      if(element.classList.contains('mobile') && element.value.length < 8)
      {
        if(!element.value.startsWith("3") && !element.value.startsWith("6")){

          return this.setMessage(element,element.placeholder + ' should be start with 3 or 6');
        }

        if(element.value.length != 8){

          return this.setMessage(element,element.placeholder + ' should be eight digits');
        }

      }
    }

    return this.setMessage(element, null);

  }


  validateNumber(element)
  {
    if(element.target.type == "tel")
    {
      var key = element.keyCode || element.which || element.charCode;

      return key <= 31 || (key >= 48 && key <= 57);
    }
  }


  setMessage(element, message)
  {
    let label:any = null;

    if(element.parentNode.lastChild.classList != null && element.parentNode.lastChild.classList.contains("invalid-feedback")){

      label = element.parentNode.lastChild;

      label.innerHTML = message;

    }else{

      label = document.createElement("div");

      label.className = "invalid-feedback";

      element.parentNode.appendChild(label);
    }

      if (message != null) {

          element.classList.remove("is-valid");
          element.classList.add("is-invalid");

      } else {

          element.classList.remove("is-invalid");
          element.classList.add("is-valid");
      }

    return message;
  }

  getDate(date:string):Date{
    let values:Array<string> =  date.split("-");
    let dateObject:Date = new Date(parseInt(values[2]), parseInt(values[1])-1, parseInt(values[0]),0,0,0,0);
    return dateObject;
  }

//   exportToExcel(tableId:string, reportName:string){

//     let rows:Array<any> = new Array();
//     let tableRow = document.getElementById(tableId).getElementsByTagName("tr");

//     for(let i =0; i< tableRow.length; i++)
//     {
//       let row:Array<any> = new Array();
//       let cells:any = tableRow[i].getElementsByTagName("th");
//       for(let j=0; j<cells.length; j++)
//       {
//         row.push(cells[j].textContent);
//       }

//       cells = tableRow[i].getElementsByTagName("td");
//       for(let j=0; j<cells.length; j++)
//       {
//         row.push(cells[j].textContent);
//       }
//       rows.push(row);
//     }
  
//   }


    
  confirmUserAction(title:string, message:string, handler:Function){
        SharedService.confirmModelSubject.next({title:title, message:message, function:handler});
    }

    confirmUserActionWithDetails(title:string, message:string,offerDTO:OfferDTO ,  handler:Function){
      SharedService.confirmDisplayDataModelSubject.next({title:title, message:message , offerDTO, function:handler});
  }

    success(success:string){


        SharedService.successMessageSubject.next(success);
    }


    info(info:string){


        SharedService.infoMessageSubject.next(info);
    }


    error(error:string){


        SharedService.errorMessageSubject.next(error);
    }

   









}
