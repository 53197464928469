import {CustomerDTO} from '../dto/customer-dto';

export class BaseCustomerFriendsDTO {

	id:number;
	customerDTO:CustomerDTO = new CustomerDTO();
	friendEmailId:string;
	friendCustomerId:number;
	status:number;
	active:boolean;
	approveDate:string;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;
}