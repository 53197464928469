import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {CustomerDTO} from '../dto/customer-dto';
import {TcodeStatusDTO} from '../dto/tcode-status-dto';
import {TcodeCityDTO} from '../dto/tcode-city-dto';
import {TcodeOccupationDTO} from '../dto/tcode-occupation-dto';

@Injectable()
export class BaseCustomerService {

	constructor(public api:Api) {

	}

	getCustomerById(id:number) : Observable<CustomerDTO> {

		return this.api.post<CustomerDTO>("Customer/getCustomerById", id);
	}

	getCustomer(customerDTO:CustomerDTO) : Observable<CustomerDTO> {

		return this.api.post<CustomerDTO>("Customer/getCustomer", customerDTO);
	}

	getCustomersByStatusId(tcodeStatusDTO:TcodeStatusDTO) : Observable<TcodeStatusDTO> {

		return this.api.post<TcodeStatusDTO>("Customer/getCustomersByStatusId", tcodeStatusDTO);
	}

	getCustomerListByStatusId(statusId:number) : Observable<Array<CustomerDTO>> {

		return this.api.post<Array<CustomerDTO>>("Customer/getCustomerListByStatusId", statusId);
	}

	getCustomerMapByStatusId(statusId:number) : Observable<Map<number, CustomerDTO>>  {

		return this.api.post<Map<number, CustomerDTO>>("Customer/getCustomerMapByStatusId", statusId);
	}

	getCustomersByCityId(tcodeCityDTO:TcodeCityDTO) : Observable<TcodeCityDTO> {

		return this.api.post<TcodeCityDTO>("Customer/getCustomersByCityId", tcodeCityDTO);
	}

	getCustomerListByCityId(cityId:number) : Observable<Array<CustomerDTO>> {

		return this.api.post<Array<CustomerDTO>>("Customer/getCustomerListByCityId", cityId);
	}

	getCustomerMapByCityId(cityId:number) : Observable<Map<number, CustomerDTO>>  {

		return this.api.post<Map<number, CustomerDTO>>("Customer/getCustomerMapByCityId", cityId);
	}

	getCustomersByOccupation(tcodeOccupationDTO:TcodeOccupationDTO) : Observable<TcodeOccupationDTO> {

		return this.api.post<TcodeOccupationDTO>("Customer/getCustomersByOccupation", tcodeOccupationDTO);
	}

	getCustomerListByOccupation(occupation:number) : Observable<Array<CustomerDTO>> {

		return this.api.post<Array<CustomerDTO>>("Customer/getCustomerListByOccupation", occupation);
	}

	getCustomerMapByOccupation(occupation:number) : Observable<Map<number, CustomerDTO>>  {

		return this.api.post<Map<number, CustomerDTO>>("Customer/getCustomerMapByOccupation", occupation);
	}

	getCustomerList() : Observable<CustomerDTO[]> {

		return this.api.get<CustomerDTO[]>("Customer/getCustomerList");
	}

	getCustomerMap() : Observable<Map<number, CustomerDTO>> {

		return this.api.get<Map<number, CustomerDTO>>("Customer/getCustomerMap");
	}

	searchCustomerList(customerDTO:CustomerDTO) : Observable<Array<CustomerDTO>> {

		return this.api.post<Array<CustomerDTO>>("Customer/searchCustomerList", customerDTO);
	}

	searchCustomerMap(customerDTO:CustomerDTO) : Observable<Map<number, CustomerDTO>> {

		return this.api.post<Map<number, CustomerDTO>>("Customer/searchCustomerMap", customerDTO);
	}

	addCustomer(customerDTO:CustomerDTO) : Observable<CustomerDTO> {

		return this.api.post<CustomerDTO>("Customer/addCustomer", customerDTO);
	}

	addCustomerList(customerList:Array<CustomerDTO>) : Observable<Array<CustomerDTO>> {

		return this.api.post<Array<CustomerDTO>>("Customer/addCustomerList", customerList);
	}

	updateCustomer(customerDTO:CustomerDTO) : Observable<CustomerDTO> {

		return this.api.post<CustomerDTO>("Customer/updateCustomer", customerDTO);
	}

	updateCustomerList(customerList:Array<CustomerDTO>) : Observable<Array<CustomerDTO>> {

		return this.api.post<Array<CustomerDTO>>("Customer/updateCustomerList", customerList);
	}

	saveCustomer(customerDTO:CustomerDTO) : Observable<CustomerDTO> {

		return this.api.post<CustomerDTO>("Customer/saveCustomer", customerDTO);
	}

	saveCustomerList(customerList:Array<CustomerDTO>) : Observable<Array<CustomerDTO>> {

		return this.api.post<Array<CustomerDTO>>("Customer/saveCustomerList", customerList);
	}

	activateCustomerById(id:number) : Observable<number>{

		return this.api.post<number>("Customer/activateCustomerById", id);
	}

	activateCustomer(customerDTO:CustomerDTO) : Observable<CustomerDTO> {

		return this.api.post<CustomerDTO>("Customer/activateCustomer", customerDTO);
	}

	activateCustomerList(customerList:Array<CustomerDTO>) : Observable<Array<CustomerDTO>> {

		return this.api.post<Array<CustomerDTO>>("Customer/activateCustomerList", customerList);
	}

	deactivateCustomerById(id:number) : Observable<number> {

		return this.api.post<number>("Customer/deactivateCustomerById", id);
	}

	deactivateCustomer(customerDTO:CustomerDTO) : Observable<CustomerDTO> {

		return this.api.post<CustomerDTO>("Customer/deactivateCustomer", customerDTO);
	}

	deactivateCustomerList(customerList:Array<CustomerDTO>) : Observable<Array<CustomerDTO>> {

		return this.api.post<Array<CustomerDTO>>("Customer/deactivateCustomerList", customerList);
	}
}