import {UserDTO} from '../dto/user-dto';
import {DeviceDTO} from '../dto/device-dto';

export class BaseLoginHistDTO {

	id:number;
	userDTO:UserDTO = new UserDTO();
	deviceDTO:DeviceDTO = new DeviceDTO();
	accessTime:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;
}