import {CustomerDTO} from '../dto/customer-dto';

export class BaseCustomerWishlistDTO {

	id:number;
	customerDTO:CustomerDTO = new CustomerDTO();
	status:number;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;
}