import {Injectable} from '@angular/core';
import {Api} from '../base-service/api.service';

import { BaseRestaurantService } from '../base-service/base-restaurant.service';
import { RestaurantDTO } from '../dto/restaurant-dto';
import { Observable } from 'rxjs';
import { CustomerOfferDTO } from '../dto/customer-offer-dto';

@Injectable()
export class RestaurantService extends BaseRestaurantService{

	constructor(public api:Api) {
		super(api);
	}

	addRestaurant(restaurantDTO:RestaurantDTO) : Observable<any> {

		return this.api.post<RestaurantDTO>("RestaurantService/addRestaurant", restaurantDTO);
	}

	updateRestaurant(restaurantDTO:RestaurantDTO) : Observable<any> {

		return this.api.post<RestaurantDTO>("RestaurantService/updateRestaurant", restaurantDTO);
	}

	getRestaurantSpecificationsLists(restaurantId:number) : Observable<RestaurantDTO> {
		
		return this.api.post<RestaurantDTO>("RestaurantService/getRestaurantSpecificationsLists/"+restaurantId ,restaurantId);

	}

	consumeCustomerOffer(customerOfferDTO:CustomerOfferDTO) : Observable<number> {
		
		return this.api.post<number>("RestaurantService/consumeCustomerOffer" ,customerOfferDTO);

	}

	searchSupplierCustomerOfferList(customerOfferDTO:CustomerOfferDTO) : Observable<Array<CustomerOfferDTO>> {

		return this.api.post<Array<CustomerOfferDTO>>("RestaurantService/searchSupplierCustomerOfferList", customerOfferDTO);
	}
	
}