import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';

 import { LocationStrategy, HashLocationStrategy } from '@angular/common'; 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

//
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// import Web services service
import {BaseCustomerCuisinesService} from './base-service/base-customer-cuisines.service';
import { BaseCustomerFriendsService} from './base-service/base-customer-friends.service';
import { BaseCustomerOfferService} from './base-service/base-customer-offer.service';
import { BaseCustomerWishlistService} from './base-service/base-customer-wishlist.service';
import { CustomerService} from './services/customer.service';
import { BaseDeviceService} from './base-service/base-device.service';
import { BaseLoginHistService} from './base-service/base-login-hist.service';
import { BaseOfferService} from './base-service/base-offer.service';
import { BaseParameterService} from './base-service/base-parameter.service';
import { BaseRestaurantCuisinesService} from './base-service/base-restaurant-cuisines.service';
import { BaseRestaurantRateService} from './base-service/base-restaurant-rate.service';
import { BaseRoleService} from './base-service/base-role.service';
import { BaseSupplierService} from './base-service/base-supplier.service';
import { BaseTcodeCityService} from './base-service/base-tcode-city.service';
import { BaseTcodeCountryService} from './base-service/base-tcode-country.service';
import { BaseTcodeCuisineService} from './base-service/base-tcode-cuisine.service';
import { BaseTcodeRestDesignService} from './base-service/base-tcode-rest-design.service';
import { BaseTcodeRestDestinationService} from './base-service/base-tcode-rest-destination.service';
import { BaseTcodeStatusService} from './base-service/base-tcode-status.service';
import { BaseTcodeSupplierTypeService} from './base-service/base-tcode-supplier-type.service';
import { BaseTcodeRestEnvironmentService } from './base-service/base-tcode-rest-environment.service';
import { BaseUserService} from './base-service/base-user.service';
import { Api} from './base-service/api.service';


//import { CustomerComponent } from './base-components/menu/';





const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


// Import containers
import { DefaultLayoutComponent } from './containers';

//// import components here.
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';


const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';


import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { DataService } from './base-service/data.service';
import { BaseRestaurantDesignService } from './base-service/base-restaurant-design.service';
import { RestaurantService } from './services/restaurant.service';
import { BaseTcodeOccupationService } from './base-service/base-tcode-occupation.service';
import { OfferService } from './services/offer.service';
import { BaseTcodeSpecialityService } from './base-service/base-tcode-speciality.service';
import { AdminService } from './services/admin.service';
import { LoginService } from './services/login.service';


//import components 
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent
  ],
  providers: [ BaseCustomerCuisinesService,   
                BaseCustomerFriendsService,    
                BaseCustomerOfferService,    
                BaseCustomerWishlistService,   
                CustomerService,  
                AdminService,
                BaseTcodeOccupationService, 
                BaseDeviceService,    
                BaseLoginHistService,  
                OfferService,   
                BaseParameterService,   
                BaseRestaurantCuisinesService,  
                BaseRestaurantRateService,   
                BaseRestaurantDesignService,
                RestaurantService,   
                BaseRoleService,    
                BaseSupplierService,    
                BaseTcodeCityService,    
                BaseTcodeCountryService,   
                BaseTcodeCuisineService,  
                BaseTcodeRestDesignService,    
                BaseTcodeRestDestinationService,   
                BaseTcodeRestEnvironmentService,   
                BaseTcodeStatusService,    
                BaseTcodeSupplierTypeService,   
                BaseTcodeSpecialityService,
                BaseUserService,
                LoginService,
                Api,
                DataService
               // { provide: LocationStrategy,  useClass: HashLocationStrategy}
            ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }