import { Injectable } from '@angular/core';
 import { BsModalService } from 'ngx-bootstrap/modal'; //Adnan commented
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';


export class SharedService {

  static confirmModelSubject:BehaviorSubject<any> = new BehaviorSubject<any>(null);

  static confirmDisplayDataModelSubject:BehaviorSubject<any> = new BehaviorSubject<any>(null);

  static successMessageSubject:BehaviorSubject<string> = new BehaviorSubject<string>(null);

  static infoMessageSubject:BehaviorSubject<string> = new BehaviorSubject<string>(null);

  static errorMessageSubject:BehaviorSubject<string> = new BehaviorSubject<string>(null);
}
