import {CustomerDTO} from '../dto/customer-dto';
import {TcodeCuisineDTO} from '../dto/tcode-cuisine-dto';

export class BaseCustomerCuisinesDTO {

	id:number;
	customerDTO:CustomerDTO = new CustomerDTO();
	cuisineDTO:TcodeCuisineDTO = new TcodeCuisineDTO();
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;
}